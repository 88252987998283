@keyframes scaleAndGlow {
  0%,
  100% {
    transform: scale(1);
    background-color: #276749; /* Adjusted for background color */
  }
  50% {
    transform: scale(1.1); /* Slightly larger scale for glow effect */
    background-color: #20c572; /* Darker shade */
  }
}

.scale-and-glow {
  animation: scaleAndGlow 1.3s 5 forwards;
}
