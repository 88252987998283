@keyframes scale {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); /* Slightly larger scale for glow effect */
  }
}

#animated-heading {
  animation: scale 1.3s 2 forwards;
}
